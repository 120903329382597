import React, { useRef, useState, useEffect } from 'react';
import { v4 as uuidv4 } from 'uuid'; //uuidv4()
import audio from '../../audios/camera-click.mp3';
import { useCookies } from 'react-cookie';
import '../tabs.css';

import {
    MissingInfoModalComp, //used to be ErrorModal
    LabFormInputsComp, //used to be formInputs
    TempMessageModalComp, //used to be Loading and Success
    PhotoGridComp, // use to be PhotoGrid
    ConfirmationModalComp // used to be ExistingSampleModal and ExistingSampleRangeModal
} from '../../../components';

const initialState = {
    fiscalYear: '2025',
    lab: '',
    labNumber: '',
    labNumberEnd: '',
    sampleType: '',
};

const videoConstraints = {
    width: 1280,
    height: 720,
    facingMode: "environment"
};

const sound = new Audio(audio)


const sampleCapQueueURI = 'https://sample-cap-queue-bjudfyf5za-uc.a.run.app/addSampleCapToRedisQueue';
const sampleCapGQLURI = 'https://gql-sample-capture-bjudfyf5za-uc.a.run.app/graphql' //live GQL
// const sampleCapQueueURI = 'https://sample-cap-queue-staging-bjudfyf5za-uc.a.run.app/addSampleCapToRedisQueue';
// const sampleCapGQLURI = 'https://gql-sample-capture-staging-bjudfyf5za-uc.a.run.app/graphql' //live GQL
// const sampleCapQueueURI = 'http://localhost:4001/addSampleCapToRedisQueue';
// const sampleCapGQLURI = 'http://localhost:4000/graphql' //local GQL

const Capture = (props) => {
    const { userEmail, handleShowModal } = props;

    const fileInput = useRef();
    const [autoIncrement, setAutoIncrement] = useState(true);
    const [images, setImages] = useState([]);
    const [errors, setErrors] = useState({});
    const [fileInputKey, setFileInputKey] = useState(1);
    const [formData, setFormData] = useState(initialState);
    const [labLocked, setLabLocked] = useState(false);
    const [labRange, setLabRange] = useState(false);
    const [loading, setLoading] = useState(false);
    const [sampleTypeLocked, setSampleTypeLocked] = useState(false);
    const [fiscalYearLocked, setFiscalYearLocked] = useState(false);
    const [showErrorModal, setShowErrorModal] = useState(false);
    const [showSuccess, setShowSuccess] = useState(false);
    const [existingSample, setExistingSample] = useState(false);
    const [openConfirmationModal, setOpenConfirmationModal] = useState(false)// use to be: const [existingSampleModalOpen, setESMOpen] = useState(false); // const [existingRangeSamplesModal, setERSModal] = useState(false);
    const [optionalThirdButtonLabel, setOptionalThirdButtonLabel] = useState("")
    const [appendLength, setAppendLength] = useState(0); //[numberErrorTap, setNET]
    const [placeHolder, setPlaceHolder] = useState("")
    const [existingPic, setExistingPic] = useState("")
    const [existingSampleID, setExistingSampleID] = useState("")
    const [existingSampleTokens, setExistingSampleTokens] = useState([])
    const [existingSampleIDsRange, setExistingSampleIDsRange] = useState([])
    const [advanceOptionsLabel, setAdvanceOptionsLabel] = useState("Lab Number Auto Advance");
    const [advanceOptionsModal, setAdvanceOptionsModal] = useState(false);
    const [showAutoPhotoTools, setShowAutoPhotoTools] = useState(false);
    const [autoPicTimeInterval, setAutoPicTimeInterval] = useState(5);
    const [takingAutoPictures, setTakingAutoPictures] = useState(false);
    const [cookies, setCookie] = useCookies(['user']);
    const [lookingForExistingSamples, setLookingForExistingSamples] = useState(false)

    const webcamRef = useRef(null);

    const handleCookie = () => {
        setCookie('lab', formData.lab, { path: '/' });
        setCookie('sampleCapSampleType', formData.sampleType, { path: '/' });
        setCookie('labLocked', labLocked, { path: '/' });
        setCookie('sampleTypeLocked', sampleTypeLocked, { path: '/' });
        setCookie('fiscalYearLocked', fiscalYearLocked, { path: '/' });
        setCookie('autoIncrement', autoIncrement, { path: '/' });
        setCookie('labNumber', formData.labNumber, { path: '/' });
        setCookie('labRange', labRange, { path: '/' });
        setCookie('showAutoPhotoTools', showAutoPhotoTools, { path: '/' });
        setCookie('autoPicTimeInterval', autoPicTimeInterval, { path: '/' });
        setCookie('advanceOptionsLabel', advanceOptionsLabel, { path: '/' });
    }
    async function getSampleCapturesByLabNumbers() {
        setLookingForExistingSamples(true)
        let getSampleCapturesByLabNumbersFetchQuery = `{
            getSampleCapturesByLabNumbers(
                fiscalYear:${JSON.stringify(formData.fiscalYear)},
                lab:${JSON.stringify(formData.lab)},
                sampleType: ${JSON.stringify(formData.sampleType)},
                labNumber: ${formData.labNumber}`;

        if (formData.labNumberEnd !== "" && formData.labNumberEnd !== null) {
            getSampleCapturesByLabNumbersFetchQuery += `, labNumberEnd:${formData.labNumberEnd}`
        }

        getSampleCapturesByLabNumbersFetchQuery += `) {
                ok
                sampleCaptures{
                    fiscalYear
                    id
                    lab
                    labNumber
                    photos {
                        id
                        token
                        url
                    }
                    sampleType
                }
            }
        }
    `;
        const getSampleCapturesByLabNumbersFetch = await fetch(sampleCapGQLURI,
            {
                method: 'POST',
                body: JSON.stringify({ query: getSampleCapturesByLabNumbersFetchQuery }),
                headers: { 'Content-type': 'application/json' }
            }
        );
        const capRangeData = await getSampleCapturesByLabNumbersFetch.json();

        if (capRangeData.data.getSampleCapturesByLabNumbers && capRangeData.data.getSampleCapturesByLabNumbers.sampleCaptures) {
            if (capRangeData.data.getSampleCapturesByLabNumbers.sampleCaptures.length === 0) {
                setExistingPic("")
            } else if (capRangeData.data.getSampleCapturesByLabNumbers.sampleCaptures.length === 1 && (formData.labNumberEnd === '' || formData.labNumberEnd === null)) {
                setExistingPic(capRangeData.data.getSampleCapturesByLabNumbers.sampleCaptures[0]);
            } else if (capRangeData.data.getSampleCapturesByLabNumbers.sampleCaptures.length > 1 && formData.labNumberEnd !== '') {
                setExistingPic("")
                let newRange = [];
                capRangeData.data.getSampleCapturesByLabNumbers.sampleCaptures.forEach(x => {
                    let photos = [];
                    x.photos.forEach(y => {
                        photos.push({ "token": y.token })
                    })
                    newRange.push({ "id": x.id, "sampleType": x.sampleType, "lab": x.lab, "labNumber": x.labNumber, "fiscalYear": x.fiscalYear, "photoTokens": photos })
                })
                setExistingSampleIDsRange(newRange);
            }
        }
        setLookingForExistingSamples(false)
    }

    const onChange = (e, arg) => {
        e.preventDefault();
        if (arg === "sampleTypeLocked" || arg === "labLocked") {
            delete errors[e];
            setErrors(errors);
        } else {
            const { name, value } = e.target;
            if ((name === 'labNumber' || name === 'labNumberEnd') && value.length) {
                setFormData({
                    ...formData,
                    [name]: parseInt(value),
                });
                delete errors.labNumber
                delete errors.labNumberEnd
                setErrors(errors)
            } else {
                setFormData({
                    ...formData,
                    [name]: value,
                });
                delete errors[name];
                setErrors(errors);
            }
        }
        setPlaceHolder(formData);
    };

    const handlePhotoAddToGrid = (photoString) => {
        setImages([...images, {
            id: uuidv4(),
            image: photoString
        }]);
        delete errors.images;
        setErrors(errors);
    }

    const handlePhotoAddToGridFileInput = () => {
        if (
            fileInput.current
            && fileInput.current.files
            && fileInput.current.files.length > 0
        ) {
            const file = fileInput.current.files[0];

            const reader = new FileReader();
            reader.readAsDataURL(file);
            reader.onload = () => {
                setImages([...images, {
                    id: uuidv4(),
                    image: reader.result,
                    file: fileInput.current.files[0],
                }]);
            };

            delete errors.images;
            setErrors(errors);
        }
    };

    const capture = () => {
        const imageSrc = webcamRef.current.getScreenshot();
        sound.play()
        handlePhotoAddToGrid(imageSrc)
    }

    const handlePhotoDelete = imageId => {
        setImages([...images.filter(el => el.id !== imageId)]);
        setFileInputKey(fileInputKey + 1);
    };

    const handleFullReset = () => {
        setImages([]);
        setFileInputKey(fileInputKey + 1);
        setFormData({ ...initialState });
        setAutoIncrement(false);
        setErrors([]);
        setLabLocked(false);
        setSampleTypeLocked(false);
        setFiscalYearLocked(false);
        setExistingPic("")
        setExistingSampleID("")
        setExistingSampleTokens([])
        setExistingSample(false)
        setAppendLength(0)
        setExistingSampleIDsRange([])
        setTakingAutoPictures(false)
    };

    const handleResetAfterSubmit = () => {
        const getLabNumber = key => {
            if (labRange) {
                return initialState[key];
            }

            if (autoIncrement) {
                if (key === 'labNumber') {
                    return Number(formData[key]) + 1;
                }
            }

            return initialState[key];
        };
        if (formData.lab === 'Hastings' && showAutoPhotoTools === true) {
            const currentLabNum = parseInt(formData.labNumber)
            const newLabNumber = currentLabNum + 1;
            setFormData({
                fiscalYear: !fiscalYearLocked ? initialState.fiscalYear : formData.fiscalYear,
                lab: !labLocked ? initialState.lab : formData.lab,
                sampleType: !sampleTypeLocked ? initialState.sampleType : formData.sampleType,
                labNumber: newLabNumber,
                labNumberEnd: '',
            });

            setTimeout(() => {
                setFileInputKey(fileInputKey + 1);
                setImages([]);
            }, (autoPicTimeInterval - 1) * 1000);
        } else {
            setFormData({
                fiscalYear: !fiscalYearLocked ? initialState.fiscalYear : formData.fiscalYear,
                lab: !labLocked ? initialState.lab : formData.lab,
                sampleType: !sampleTypeLocked ? initialState.sampleType : formData.sampleType,
                labNumber: getLabNumber('labNumber'),
                labNumberEnd: getLabNumber('labNumberEnd'),
            });
            setImages([]);
            setErrors({});
            setFileInputKey(fileInputKey + 1);
            setExistingPic("")
            setExistingSampleID("")
            setExistingSampleTokens([])
            setExistingSample(false)
            setAppendLength(0)
            setExistingSampleIDsRange([])
        }
    };

    const handleSubmit = async e => {
        e.preventDefault();

        const formErrors = {};

        if (!formData.lab) {
            formErrors.lab = { error: true, message: 'Lab is required' };
        }

        if (!formData.sampleType) {
            formErrors.sampleType = { error: true, message: 'Sample Type is required' };
        }

        if (!formData.fiscalYear) {
            formErrors.fiscalYear = { error: true, message: 'Year is required' };
        }

        if (labRange) {
            if (!formData.labNumber
                || (labRange && !formData.labNumberEnd)
                || (labRange && (Number(formData.labNumber) >= Number(formData.labNumberEnd)))
            ) {
                formErrors.labNumber = { error: true, message: 'Lab Range is invalid' };
                formErrors.labNumberEnd = { error: true };
            }
            else if (((Number(formData.labNumberEnd) - Number(formData.labNumber) > 200))) {
                formErrors.labNumber = { error: true, message: 'Cannot include more than 200 samples in a Range' };
                formErrors.labNumberEnd = { error: true };
            }
        }

        if (!labRange) {
            if (!formData.labNumber) {
                formErrors.labNumber = { error: true, message: 'Lab Number is required' };
            }
        }

        if (images.length === 0) {
            formErrors.images = { error: true, message: 'At least 1 photo is required' };
        }

        if (Object.keys(formErrors).length > 0) {
            //   console.log('error', formErrors);
            setShowErrorModal(true);
            setErrors(formErrors);
            return;
        }
        if (existingSample === true && existingSampleIDsRange.length === 0) {
            handleOpenConfirmationModal()  //   handleOpenNETModal();
            setOptionalThirdButtonLabel("APPEND")
        } else if (existingSample === true && existingSampleIDsRange.length > 0) {
            handleOpenConfirmationModal(); //handleOpenERSModal();
        } else if (existingSample === false) {

            setLoading(true);

            const photos = images.map(({ image }) => image.split("data:image/jpeg;base64,").pop());
            const variables = { environment: "production", data: { ...formData, photos, userEmail } };

            await fetch(sampleCapQueueURI, { method: 'POST', body: JSON.stringify({ sampleCap: JSON.stringify(variables) }), headers: { 'Content-Type': 'application/json' } });

            handleResetAfterSubmit();
            setLoading(false);
            setShowSuccess(true);

            setTimeout(() => {
                setShowSuccess(false);
            }, 1500);
        }
    };

    const handleExistingSampleSubmit = async e => {
        setLoading(true);
        setOpenConfirmationModal(false)
        const photos = images.map(({ image }) => image.split("data:image/jpeg;base64,").pop());
        const variables = { environment: "production", data: { ...formData, photos, numberErrorTap: appendLength, existingSampleID, overwrite: false, userEmail } };

        await fetch(sampleCapQueueURI, { method: 'POST', body: JSON.stringify({ sampleCap: JSON.stringify(variables) }), headers: { 'Content-Type': 'application/json' } });

        setLoading(false);
        setShowSuccess(true);
        setTimeout(() => {
            setShowSuccess(false);
        }, 1500);
        handleResetAfterSubmit();
    }

    const handleOverWrite = async e => {
        setLoading(true);
        setOpenConfirmationModal(false)
        const photos = images.map(({ image }) => image.split("data:image/jpeg;base64,").pop());
        let variables;
        if (existingSampleIDsRange.length === 0) {
            variables = { environment: "production", data: { ...formData, photos, existingSampleID, existingSampleTokens: JSON.stringify(existingSampleTokens), overwrite: true, userEmail } };
        } else {
            variables = { environment: "production", data: { ...formData, photos, overwrite: true, overwriteWithinRangeIDs: JSON.stringify(existingSampleIDsRange), userEmail } };
        }

        await fetch(sampleCapQueueURI, { method: 'POST', body: JSON.stringify({ sampleCap: JSON.stringify(variables) }), headers: { 'Content-Type': 'application/json' } });

        setLoading(false);
        setShowSuccess(true);
        setTimeout(() => {
            setShowSuccess(false);
        }, 1500);
        handleResetAfterSubmit();
    }

    const handleRange = () => {
        setLabRange(true);
        setAutoIncrement(false);

        delete errors.labNumber;
        delete errors.labNumberEnd;
        setErrors(errors);
        setShowErrorModal(false);

        setFormData({
            ...formData,
            labNumber: initialState.labNumber,
            labNumberEnd: initialState.labNumberEnd,
        });
    };

    const startAutoPictures = () => {
        sound.load();
        const formErrors = {};

        if (!formData.lab) {
            formErrors.lab = { error: true, message: 'Lab is required' };
        }

        if (!formData.sampleType) {
            formErrors.sampleType = { error: true, message: 'Sample Type is required' };
        }

        if (!formData.fiscalYear) {
            formErrors.fiscalYear = { error: true, message: 'Year is required' };
        }

        if (!formData.labNumber) {
            formErrors.labNumber = { error: true, message: 'Lab Number is required' };
        }

        if (autoPicTimeInterval < 5) {
            formErrors.interval = { error: true, message: 'Time Interval must be at least 5 seconds' };
        }

        if (!labLocked) {
            formErrors.labLocked = { error: true, message: 'Lab must be locked in Auto Picture mode' };
        }

        if (!sampleTypeLocked) {
            formErrors.sampleTypeLocked = { error: true, message: 'Sample Type must be locked in Auto Picture mode' };
        }


        if (Object.keys(formErrors).length > 0) {
            console.log('error', formErrors);
            setShowErrorModal(true);
            setErrors(formErrors);
            return;
        }

        if (Object.keys(formErrors).length === 0 && autoPicTimeInterval >= 5) {
            setTakingAutoPictures(true)
        } else {
            console.log("missing info or interval < 5")
        }
    }

    const stopAutoPictures = () => {
        setTakingAutoPictures(false)
    }

    const autoSubmitPics = async () => {
        let existing;
        const imageSrc = webcamRef.current.getScreenshot();
        sound.play()
        let photoStringToUse;
        handlePhotoAddToGrid(imageSrc);
        photoStringToUse = imageSrc;
        const getSampleCapturesByLabNumbersFetchQuery = `{
            getSampleCapturesByLabNumbers(
                fiscalYear:${JSON.stringify(formData.fiscalYear)},
                lab:${JSON.stringify(formData.lab)},
                sampleType: ${JSON.stringify(formData.sampleType)},
                labNumber: ${formData.labNumber}
            ) {
                ok
                sampleCaptures{
                    fiscalYear
                    id
                    lab
                    labNumber
                    photos {
                        id
                        token
                        url
                    }
                    sampleType
                }
            }
        }
    `;
        const existingFetch = await fetch(sampleCapGQLURI, { method: 'POST', body: JSON.stringify({ query: getSampleCapturesByLabNumbersFetchQuery }), headers: { 'Content-type': 'application/json' } });
        const existingJson = await existingFetch.json();
        existing = existingJson.data.getSampleCapturesByLabNumbers.sampleCaptures.length;


        if (existing > 0) {
            let eToken = [];
            setExistingSampleID(existingJson.data.getSampleCapturesByLabNumbers.sampleCaptures[0].id)
            existingJson.data.getSampleCapturesByLabNumbers.sampleCaptures[0].photos.forEach(x => {
                eToken.push(x.token)
            })
            setExistingSampleTokens({ fiscalYear: existingJson.data.getSampleCapturesByLabNumbers.sampleCaptures[0].fiscalYear, sampleType: existingJson.data.getSampleCapturesByLabNumbers.sampleCaptures[0].sampleType, lab: existingJson.data.getSampleCapturesByLabNumbers.sampleCaptures[0].lab, labNumber: existingJson.data.getSampleCapturesByLabNumbers.sampleCaptures[0].labNumber, photoTokens: eToken })
            setTakingAutoPictures(false)
            handleOpenConfirmationModal()
            setOptionalThirdButtonLabel("APPEND")
        }
        else {
            let photos = [];
            photos.push(photoStringToUse.split("data:image/jpeg;base64,").pop())
            const variables = { environment: "production", data: { ...formData, photos, userEmail } };

            await fetch(sampleCapQueueURI, { method: 'POST', body: JSON.stringify({ sampleCap: JSON.stringify(variables) }), headers: { 'Content-Type': 'application/json' } });

            const currentLabNum = parseInt(formData.labNumber)
            const newLabNumber = currentLabNum + 1;

            setShowSuccess(true);
            setFormData({
                ...formData,
                ...!labLocked && { lab: initialState.lab },
                ...!sampleTypeLocked && { sampleType: initialState.sampleType },
                ...!fiscalYearLocked && { fiscalYear: initialState.fiscalYear },
                labNumber: newLabNumber,
                labNumberEnd: '',
            });

            setTimeout(() => {
                setErrors({});
                setFileInputKey(fileInputKey + 1);
                setAppendLength(0)
                setImages([]);
                setShowSuccess(false);
            }, (autoPicTimeInterval - 1) * 1000);
        }

    }

    const handleCloseError = () => setShowErrorModal(false);
    //before:
    // const handleOpenNETModal = () => setESMOpen(true);
    // const handleOpenERSModal = () => setERSModal(true);
    //new: 
    const handleOpenConfirmationModal = () => setOpenConfirmationModal(true);
    const handleCloseConfirmationModal = () => setOpenConfirmationModal(false);
    const handleAdvanceOptionsLabel = (option) => setAdvanceOptionsLabel(option);

    const handleSetAdvanceOptions = (option) => {
        switch (option) {
            case 'Range':
                handleRange();
                setShowAutoPhotoTools(false);
                setAutoIncrement(false);
                break;

            case 'Auto Pictures':
                setShowAutoPhotoTools(true);
                setAutoIncrement(true);
                setLabRange(false);
                break;

            default:
                // Lab Number Auto Advance
                setAutoIncrement(true);
                setLabRange(false);
                setShowAutoPhotoTools(false);
                break;
        }
    }

    const handleSetAutoPicTimeInterval = (e) => {
        setAutoPicTimeInterval(e.target.value)
    }

    const preventMinus = (e) => {
        if (e.code === 'Minus' || e.which > 57 || e.which === 32) {
            e.preventDefault();
        }
    };

    useEffect(() => {
        if (cookies.lab && cookies.sampleCapSampleType && cookies.labLocked && cookies.sampleTypeLocked && cookies.fiscalYearLocked && cookies.labNumber && cookies.labRange
            && cookies.showAutoPhotoTools && cookies.autoIncrement && cookies.autoPicTimeInterval && cookies.advanceOptionsLabel) {
            setFormData((prevState) => {
                return { ...prevState, lab: cookies.lab, sampleType: cookies.sampleCapSampleType, labNumber: cookies.labNumber }
            });
            if (cookies.labLocked === "false") {
                setLabLocked(false);
            } else {
                setLabLocked(true);
            }
            if (cookies.sampleTypeLocked === "false") {
                setSampleTypeLocked(false);
            } else {
                setSampleTypeLocked(true);
            }
            if (cookies.fiscalYearLocked === "false") {
                setFiscalYearLocked(false);
            } else {
                setFiscalYearLocked(true);
            }
            if (cookies.labRange === "false") {
                setLabRange(false);
            } else {
                setLabRange(true);
            }
            if (cookies.showAutoPhotoTools === "false") {
                setShowAutoPhotoTools(false);
            } else {
                setShowAutoPhotoTools(true);
                setFormData((prevState) => {
                    return { ...prevState, labNumber: parseInt(cookies.labNumber) }
                });
            }
            if (cookies.autoIncrement === "false") {
                setAutoIncrement(false);
            } else {
                setAutoIncrement(true);
            }
            setAutoPicTimeInterval(cookies.autoPicTimeInterval);
            setAdvanceOptionsLabel(cookies.advanceOptionsLabel || "Lab Number Auto Advance")
        }
        // eslint-disable-next-line 
    }, [])

    useEffect(() => {
        if (formData !== "" && formData !== placeHolder) {
            let variables = {};
            if (formData.labNumber === "" || (formData.labNumberEnd === "" && formData.labNumber === "")) {
                variables = {};
            } else if (formData.labNumberEnd !== "" && formData.labNumber !== "" && (formData.labNumberEnd > formData.labNumber)) {
                variables = {
                    fiscalYear: formData.fiscalYear,
                    lab: formData.lab,
                    labNumber: parseInt(formData.labNumber),
                    sampleType: formData.sampleType,
                    labNumberEnd: parseInt(formData.labNumberEnd)
                };
            } else if (formData.labNumberEnd === "" && formData.labNumber !== "") {
                variables = {
                    fiscalYear: formData.fiscalYear,
                    lab: formData.lab,
                    labNumber: parseInt(formData.labNumber),
                    sampleType: formData.sampleType
                };
            }
            if (variables && variables.fiscalYear && variables.labNumber && variables.lab && variables.sampleType) {
                getSampleCapturesByLabNumbers();
                handleCookie();
            }
        }
        // eslint-disable-next-line
    }, [formData])

    useEffect(() => {
        if (existingPic !== "" && existingSampleIDsRange.length === 0) {
            if (existingPic && existingPic.photos !== null) {
                setAppendLength(existingPic.photos.length);
                setExistingSample(true)
                setExistingSampleID(existingPic.id)
                let tokens = [];
                existingPic.photos.forEach(x => {
                    tokens.push(x.token)
                })
                setExistingSampleTokens({ fiscalYear: existingPic.fiscalYear, sampleType: existingPic.sampleType, lab: existingPic.lab, labNumber: existingPic.labNumber, photoTokens: tokens });
            } else if (existingPic && existingPic.photos === null) {
                setExistingSampleID("")
                setExistingSampleTokens([])
                setExistingSample(false)
                setAppendLength(0)
            } else {
                setExistingSampleID("")
                setExistingSampleTokens([])
                setExistingSample(false)
                setAppendLength(0)
            }
        } else if (existingSampleIDsRange.length > 0 && existingPic === "") {
            setExistingSample(true)
        } else {
            setExistingSampleID("")
            setExistingSampleTokens([])
            setExistingSample(false)
            setAppendLength(0)
        }
    }, [existingPic, existingSampleIDsRange])

    useEffect(() => {
        if (takingAutoPictures === true && images.length === 0) {
            const interval = setInterval(async () => {
                autoSubmitPics();
            }, 1000)
            return () => clearInterval(interval);
        }
        // eslint-disable-next-line
    }, [takingAutoPictures, formData, images])


    return (
        <>
            {loading && <TempMessageModalComp isOpen={loading} isSuccessMessage={false} message={"Loading"} />}
            {showSuccess && <TempMessageModalComp isOpen={showSuccess} isSuccessMessage={true} message={"Success"} />}
            {showErrorModal && Object.keys(errors).length > 0 && (<MissingInfoModalComp missingInfo={errors} handleCloseModal={handleCloseError} isOpen={showErrorModal} />)}
            <form className="capture-form" encType={"multipart/form/data"}>
                <div style={{ "marginTop": "5px" }}>
                    <div className="card mx-auto">
                        <div className="card-body" style={{ marginTop: "-14px" }}>
                            <ConfirmationModalComp
                                isOpen={openConfirmationModal}
                                handleCloseModal={handleCloseConfirmationModal}
                                handleSubmit={handleOverWrite}
                                title={"OVERWRITING WARNING"}
                                message={optionalThirdButtonLabel === "" ? ["THERE ARE IMAGES SAVED UNDER THE LAB/SAMPLETYPE/LABNUMBER.", "YOU CAN OVERWRITE THE IMAGES SAVED OR APPEND A 1 TO THE FILENAME TO PREVENT FROM DELETING NEEDED IMAGES.", "*APPENDED IMAGES WILL SHOW UP IN LIMS.*"] : ["There are sample with images saved in the range you have entered are you sure you want to overwrite them?"]}
                                submitButtonLabel={"OVERWRITE"}
                                optionalThirdButtonLabel={optionalThirdButtonLabel}
                                optionalThirdOption={handleExistingSampleSubmit}
                                setOptionalThirdButtonLabel={setOptionalThirdButtonLabel}
                            />
                            <LabFormInputsComp
                                errors={errors}
                                formData={formData}
                                yearsList={["2021", "2022", "2023", "2024", "2025"]}
                                labList={["Amarillo", "Dodge City", "Hastings"]}
                                sampleTypeList={["Feed", "Fertilizer", "Plant", "Soil", "Environmental", "Other"]}
                                onChange={onChange}
                                preventMinus={preventMinus}
                                withLocksAndRange={{
                                    autoIncrement: {
                                        value: autoIncrement,
                                    },
                                    labLocked: {
                                        setter: setLabLocked,
                                        value: labLocked,
                                    },
                                    labRange: {
                                        value: labRange,
                                    },
                                    sampleTypeLocked: {
                                        setter: setSampleTypeLocked,
                                        value: sampleTypeLocked,
                                    },
                                    fiscalYearLocked: {
                                        setter: setFiscalYearLocked,
                                        value: fiscalYearLocked,
                                    }
                                }}
                                handleAdvanceOptionsLabel={handleAdvanceOptionsLabel}
                                advanceOptionsLabel={advanceOptionsLabel}
                                advanceOptionsModal={advanceOptionsModal}
                                setAdvanceOptionsModal={setAdvanceOptionsModal}
                                handleSetAdvanceOptions={handleSetAdvanceOptions}
                            />
                            {/* <div className="divider" /> */}
                            <hr />
                            {/* Start, stop, interval for auto pictures */}
                            {showAutoPhotoTools &&
                                <div className="auto-photo-controls-div">
                                    <span className='seconds-input '>
                                        <label className='seconds-label'>seconds</label>
                                        <input className="auto-control" type='number' min='5' defaultValue={autoPicTimeInterval} onKeyPress={preventMinus} onChange={(e) => handleSetAutoPicTimeInterval(e)}></input>

                                    </span>
                                    {takingAutoPictures &&
                                        <>
                                            <button disabled className='auto-control secondary'>Start</button>
                                            <button className='auto-control red' onClick={(e) => { e.preventDefault(); stopAutoPictures(); handleCookie() }}>Stop</button>
                                        </>
                                    }
                                    {!takingAutoPictures &&
                                        <>
                                            <button className='auto-control green' onClick={(e) => { e.preventDefault(); startAutoPictures() }}>Start</button>
                                            <button disabled className='auto-control secondary'>Stop</button>
                                        </>
                                    }
                                </div>
                            }
                            <PhotoGridComp
                                images={images}
                                handlePhotoDelete={handlePhotoDelete}
                                handlePhotoEnlarge={handleShowModal}
                                videoConstraints={videoConstraints}
                                webcamRef={webcamRef}
                                showAutoPhotoTools={showAutoPhotoTools}
                                fileInput={fileInput}
                                fileInputKey={fileInputKey}
                                handlePhotoAddToGridFileInput={handlePhotoAddToGridFileInput}
                                errors={errors}
                            />
                        </div>
                    </div>
                </div>
                <div className="sample-capture-button-container">
                    <div className="sample-capture-buttons">
                        <button className="secondary" onClick={(e) => { e.preventDefault(); handleFullReset() }}>Reset</button>
                        {!lookingForExistingSamples &&
                            <button className="submit-button" buttontype="submit" type="primary" onClick={(e) => { handleSubmit(e); }}>Submit</button>
                        }
                        {lookingForExistingSamples &&
                            <button className="disabled-submit-button" type="primary" onClick={(e) => { e.preventDefault() }}>Looking for Existing Samples...</button>
                        }
                        {showAutoPhotoTools &&
                            <button className="green capture-image-button" onClick={(e) => { e.preventDefault(); capture() }}>Capture Image</button>
                        }
                    </div>
                </div>
            </form>
        </>
    )
}

export default Capture;