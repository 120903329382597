import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import './view.css';

const sampleCapGQLURI = 'https://gql-sample-capture-bjudfyf5za-uc.a.run.app/graphql' //live GQL Prod
// const sampleCapGQLURI = 'https://gql-sample-capture-staging-bjudfyf5za-uc.a.run.app/graphql' //live GQL Staging
//const sampleCapGQLURI = 'http://localhost:4000/graphql' //local GQL

const SampleView = () => {
  const params = useParams();
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState([]);
  async function getSampleCapturesByLabNumbers() {
    let getSampleCapturesByLabNumbersFetchQuery = `{
        getSampleCapturesByLabNumbers(
            fiscalYear:${JSON.stringify(params.fiscalYear)},
            lab:${JSON.stringify(params.lab)},
            sampleType: ${JSON.stringify(params.sampleType)},
            labNumber: ${params.labNumber}`;

    if (params.labNumberEnd !== "" && params.labNumberEnd !== null) {
      getSampleCapturesByLabNumbersFetchQuery += `, labNumberEnd:${params.labNumberEnd}`
    }

    getSampleCapturesByLabNumbersFetchQuery += `) {
            ok
            sampleCaptures{
                fiscalYear
                id
                lab
                labNumber
                photos {
                    id
                    token
                    url
                }
                sampleType
            }
        }
    }
`;
    const getSampleCapturesByLabNumbersFetch = await fetch(sampleCapGQLURI,
      {
        method: 'POST',
        body: JSON.stringify({ query: getSampleCapturesByLabNumbersFetchQuery }),
        headers: { 'Content-type': 'application/json' }
      }
    );

    const tempData = await getSampleCapturesByLabNumbersFetch.json();

    if(tempData && tempData.data && tempData.data.getSampleCapturesByLabNumbers && tempData.data.getSampleCapturesByLabNumbers.sampleCaptures && tempData.data.getSampleCapturesByLabNumbers.sampleCaptures.length) {
      setData(tempData.data.getSampleCapturesByLabNumbers.sampleCaptures);
    }
    setLoading(false);
  }

  useEffect(() => {
    if (params) {
      setLoading(true);
      const variables = {
        fiscalYear: params.fiscalYear,
        lab: params.lab,
        labNumber: parseInt(params.labNumber),
        sampleType: params.sampleType,
        labNumberEnd: parseInt(params.labNumberEnd)
      };
      if (variables && variables.fiscalYear && variables.labNumber && variables.lab && variables.sampleType) {
        getSampleCapturesByLabNumbers();
      }
    }
    // eslint-disable-next-line
  }, [params])

  return (
    <div className="view">
      {loading && (
        <div className="sample-capture-modal">
          <div className="sample-capture-modal-wrap">
            <div className="inner loading-wrapper">
              <div className="loading">
                Loading...
              </div>
            </div>
          </div>
        </div>
      )}
      {!loading
        && data.length === 0
        && (
          <>
            <div className="details-wrapper">
              <div>Sample not found for this fiscalYear, lab, sampleType, labNumber</div>
            </div>
          </>
        )}
      {!loading
        && data.length > 0
        && (
          <>
            {data.map(sample => (
              <div>
                {sample.photos.map(x => (
                  <div className="sample-image" key={x.token}>
                    <img alt="sample" src={x.url} />
                  </div>
                ))}
              </div>
            ))
            }
          </>
        )}
    </div>
  );
};

export default SampleView;

