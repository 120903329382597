import React, { useState } from 'react';
import '../tabs.css';

import {
    MissingInfoModalComp
} from '../../../components';

// const domain = 'http://localhost:3000'; //LOCAL TESTING
// const domain = 'https://sample-capture-staging-bjudfyf5za-uc.a.run.app'; //STAGING
const domain = 'https://sample-capture.servitech.com'; //PROD

const initialState = {
    fiscalYear: "2025",
    lab: "default",
    labNumber: "",
    labNumberEnd: "",
    sampleType: "default"
}

const Archive = () => {

    const [errors, setErrors] = useState({});
    const [showErrorModal, setShowErrorModal] = useState(false);
    const [formData, setFormData] = useState(initialState);
    const [toggleRange, setToggleRange] = useState("OFF");

    const onChange = e => {
        const { name, value } = e.target;

        if ((name === 'labNumber' || name === 'labNumberEnd') && value.length) {

            setFormData({ ...formData, [name]: parseInt(value) })
        } else {
            setFormData({ ...formData, [name]: value })
        }
        delete errors[name];
        setErrors(errors);
    }

    const handleCloseError = () => setShowErrorModal(false);

    const getSamples = async (e) => {
        e.preventDefault();
        const formErrors = {};
        if (formData.lab === 'default') {
            formErrors.lab = { error: true, message: "Lab is required" }
        }
        if (formData.sampleType === 'default') {
            formErrors.sampleType = { error: true, message: "Sample Type is required" }
        }
        if (formData.labNumber === "" || formData.labNumber === 0) {
            formErrors.labNumber = { error: true, message: "Lab Number is required" }
        }
        if ((formData.labNumberEnd !== "" && formData.labNumberEnd !== 0) && (parseInt(formData.labNumber) >= parseInt(formData.labNumberEnd))) {
            formErrors.labNumberEnd = { error: true, message: "Range End must be higher than Range Start" }
        }
        if (Object.keys(formErrors).length > 0) {
            setShowErrorModal(true);
            setErrors(formErrors);
            return;
        }
    };

    const openInNewTab = (url) => { window.open(url, '_blank', 'noreferrer') }

    const handleRangeToggle = () => {
        if (toggleRange === "ON") {
            setToggleRange("OFF");
            setFormData({...formData, labNumberEnd: ""})
        } else {
            setToggleRange("ON");
        }
    }

    const preventMinus = (e) => {
        if (e.code === 'Minus' || e.which > 57  || e.which === 32) {
            e.preventDefault();
        }
    };

    return (
        <>
            <link rel="stylesheet" href="https://fonts.sandbox.google.com/css2?family=Material+Symbols+Outlined:opsz,wght,FILL,GRAD@20..48,100..700,0..1,-50..200" />
            {showErrorModal && Object.keys(errors).length > 0 && (<MissingInfoModalComp missingInfo={errors} handleCloseModal={handleCloseError} isOpen={showErrorModal} />
                // <div className="error">
                //     <div aria-hidden="true" className="title-wrapper" onClick={() => handleCloseError()}>
                //         <img src={closeCircle} alt="closeCircle" />
                //         <div>
                //             Error
                //         </div>
                //         <img src={closeX} alt="close" />
                //     </div>
                //     <ul>
                //         {Object.entries(errors).map(el => (
                //             el[1].message
                //             &&
                //             <li key={el[1].message}>
                //                 {el[1].message}
                //             </li>
                //         ))}
                //     </ul>
                // </div>
            )}
            <div style={{ "marginTop": "5px" }}>
                <div className="card mx-auto">
                    <div className="card-body" style={{ marginTop: "-14px" }}>
                        <div className="sample-capture-action-wrapper">
                            <div
                                className={errors && errors.lab && errors.lab.error
                                    ? 'select-wrapper has-error'
                                    : 'select-wrapper'}
                            >
                                <div>
                                    <label className="sample-option-label">Lab</label>
                                </div>
                                <select
                                    name="lab"
                                    onChange={e => onChange(e)}
                                    style={{ width: '100%' }}
                                    value={formData.lab}
                                >
                                    <option disabled value="default">Select lab</option>
                                    <option value="Amarillo">Amarillo</option>
                                    <option value="Dodge City">Dodge City</option>
                                    <option value="Hastings">Hastings</option>
                                </select>
                            </div>
                            <div
                                className={errors && errors.fiscalYear && errors.fiscalYear.error
                                    ? 'select-wrapper has-error'
                                    : 'select-wrapper'}
                            >
                                <div>
                                    <label className="sample-option-label">Fiscal Year</label>
                                </div>
                                <select
                                    name="fiscalYear"
                                    onChange={e => onChange(e)}
                                    style={{ width: '100%' }}
                                    value={formData.fiscalYear}
                                >
                                    <option value="2017">2017</option>
                                    <option value="2018">2018</option>
                                    <option value="2019">2019</option>
                                    <option value="2020">2020</option>
                                    <option value="2021">2021</option>
                                    <option value="2022">2022</option>
                                    <option value="2023">2023</option>
                                    <option value="2024">2024</option>
                                    <option value="2025">2025</option>
                                </select>
                            </div>
                            <div
                                className={errors && errors.sampleType && errors.sampleType.error
                                    ? 'select-wrapper has-error'
                                    : 'select-wrapper'}
                            >
                                <div>
                                    <label className="sample-option-label">Sample Type</label>
                                </div>
                                <select
                                    name="sampleType"
                                    onChange={e => onChange(e)}
                                    style={{ width: '100%' }}
                                    value={formData.sampleType}
                                >
                                    <option disabled value="default">Select Sample Type</option>
                                    <option value="Feed">Feed</option>
                                    <option value="Fertilizer">Fertilizer</option>
                                    <option value="Plant">Plant</option>
                                    <option value="Soil">Soil</option>
                                    <option value="Environmental">Environmental</option>
                                    <option value="Other">Other</option>
                                </select>
                            </div>
                            <div className='sample-capture-lab-number-wrapper'>
                                <div className='lab-range'>
                                    {/*Not Range or Starting Lab Number for Range*/}
                                    <div className="lab-number-div">
                                        <div>
                                            <label className="sample-option-label">{toggleRange === "OFF" ? "Lab Number" : "Range Start"}</label>
                                        </div>
                                        <input className="lab-number-input"
                                            //error={errors && errors.labNumber && errors.labNumber.error}
                                            type="number"
                                            label="Lab Number"
                                            name="labNumber"
                                            onChange={e => onChange(e)}
                                            onKeyPress={e => preventMinus(e)}
                                            value={formData.labNumber}
                                        />
                                    </div>
                                    {/*Range*/}
                                    {toggleRange === "ON" &&
                                        <div className="lab-number-div">
                                            <div>
                                                <label className="sample-option-label">Range End</label>
                                            </div>
                                            <input className="lab-number-input"
                                                //error={errors && errors.labNumberEnd && errors.labNumberEnd.error}
                                                type="number"
                                                label="Range End"
                                                name="labNumberEnd"
                                                onChange={e => onChange(e)}
                                                onKeyPress={e => preventMinus(e)}
                                                value={formData.labNumberEnd}
                                            />
                                        </div>
                                    }
                                </div>
                            </div>
                        </div>
                        <div className="archive-search-wrapper">
                            <button className="range-toggle-button secondary" onClick={(handleRangeToggle)}>
                                Range {toggleRange}
                            </button>
                            {(formData.lab === 'default' || formData.sampleType === 'default' || formData.labNumber === "" || (formData.labNumberEnd !== "" && parseInt(formData.labNumber) >= parseInt(formData.labNumberEnd)) || (formData.labNumberEnd !== 0 && (parseInt(formData.labNumber) >= parseInt(formData.labNumberEnd)))) &&
                                <button className="submit-button submit-button-archive" onClick={(e) => getSamples(e)} type="primary">
                                    Search
                                </button>
                            }
                            {(formData.lab !== 'default' && formData.sampleType !== 'default' && formData.labNumber !== "" && (formData.labNumberEnd !== "" && parseInt(formData.labNumber) < parseInt(formData.labNumberEnd)) && (formData.labNumberEnd !== 0 && parseInt(formData.labNumber) < parseInt(formData.labNumberEnd))) &&
                                <button className="submit-button submit-button-archive" type="primary" onClick={(e) => { openInNewTab(`${domain}/sample-capture/view/${formData.fiscalYear}/${formData.lab}/${formData.labNumber}/${formData.labNumberEnd}/${formData.sampleType}`) }}>
                                    Search
                                </button>
                            }
                            {(formData.lab !== 'default' && formData.sampleType !== 'default' && (formData.labNumber !== "" && formData.labNumber !== 0) && (formData.labNumberEnd === "" && formData.labNumberEnd !== 0)) &&
                                <button className="submit-button submit-button-archive" type="primary" onClick={(e) => { openInNewTab(`${domain}/sample-capture/view/${formData.fiscalYear}/${formData.lab}/${formData.labNumber}/0/${formData.sampleType}`) }}>
                                    Search
                                </button>
                            }
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default Archive;